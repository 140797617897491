import ReactDOM from 'react-dom/client';
import './index.module.scss';
import './styles/themeColors.scss';
import Root from './components/Root';
import { store } from "./config/store";
import { datadogRum } from '@datadog/browser-rum';

const dev = process.env.NODE_ENV === 'development';
if(!dev){
    datadogRum.init({
        applicationId: '08a7a02a-5ad9-4ee7-ad2b-b372e2396680',
        clientToken: 'pub38f1031f92eb00a6b2199144d869877f',
        site: 'datadoghq.com',
        service:'linze-web',
        //  service: 'my-web-application',
        //  env: 'production',
        //  version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input'
    });
    datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if(window?.Cypress){
    window.store = store;
}

root.render(<Root/>);