import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import NoDataList from "../../../../components/NoDataList";
import ProfileCard from "../../../../components/ProfileCard";
import { images } from "../../../../constants/images";
import { MyBuysProps } from "../../../../interfaces/components";
import styles from "./entryPoint.module.scss";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { addPointsInText } from "../../../../helpers/app";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Pagination from "../../../../components/Pagination";

const EntryPoint = ({ 
    getMyBuysRequest, 
    cleanMyBuys,
    fetchingMyBuys, 
    myBuys,
    totalBuys
 }: MyBuysProps) => {
    const params = useParams();
    const navigate = useNavigate();
    const [page, setPage] = useState<number>(1);
    const [totalMyBuys, setTotalMyBuys] = useState<number>(1);

    const matches = useMediaQuery('(max-width:1024px)');
	const [isSmallScreen, setIsSmallScreen] = useState(matches);
  
	useEffect(() => {
	  setIsSmallScreen(matches);
	}, [matches]);

    const handlePressRow = (id: string) => {
        navigate(`/profile/my_buys/${id}/management`);
    }

    const onOpenLink = (event: any, id: string) => {
        event.stopPropagation();
        navigate(`/publication/${id}`);
    }

    useEffect(() => {
        if(fetchingMyBuys === "failure"){
            cleanMyBuys();
        }
        if(fetchingMyBuys === "success"){
            cleanMyBuys();
            setTotalMyBuys(Math.ceil(totalBuys / 20));
        }
    }, []);

    useEffect(() => {
        getMyBuysRequest(page);
    }, [page]);

    return (
        params.id 
        ? 
            <Outlet />
        :
            <>
                {
                    fetchingMyBuys === "loading"
                    ?
                        <div className={styles.loaderBuys} id="loaderBuys">
                            <CircularProgress 
                                size={styles.loader}
                                classes={{
                                    colorPrimary: styles.loader
                                }}
                            />
                            <p className={styles.text}>Cargando compras</p>
                        </div>
                    :
                        myBuys && myBuys.length === 0
                        ?
                            <NoDataList
                                title="No tienes compras aún"
                                text="Cuando hayas realizado la compra de un auto podrás ver los detalles en esta sección."
                                image={images.noBuys}
                            />
                        :
                            <ProfileCard>
                                <div className={styles.myBuysContainer}>
                                    <div id="myPublicationCard" className={styles.myBuys}>
                                    { isSmallScreen ?
                                            myBuys?.map((item: any, index: number) => {
                                                let stateCode = "";
                                                switch(item?.publication?.status_brief?.code){
                                                    case "rejected":
                                                        stateCode = styles.red
                                                        break;
                                                    case "payment":
                                                    case "delivered":
                                                    case "sold_out":
                                                        stateCode = styles.green
                                                        break;
                                                    case "inspection":
                                                    case "pending":
                                                    case "documentation_signature":
                                                        stateCode = styles.gray
                                                        break;
                                                    default:
                                                        stateCode = styles.blue
                                                        break;
                                                }
                                                return(
                                                    <div className={styles.cardContainer} onClick={() => handlePressRow(item?._id)} key={index}>
                                                        <img src={(item?.publication?.pictures && item?.publication?.pictures[0]?.path) ?? images.noImagePublication} alt="Buy" />
                                                        <div className={styles.cardDescription}>
                                                            <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.publication?.primary_description} ${item?.publication?.title}`}</p>
                                                            <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.publication?.price)}</p>
                                                            <p className={styles.text}>{addPointsInText(item?.publication?.secondary_description)} km</p>
                                                            <div className={styles.cardOptionsContainer}>
                                                                <div className={`${styles.text} ${styles.state} ${stateCode}`}>{item?.publication?.status_brief?.name.toUpperCase()}</div>
                                                                <div className={styles.cardAction}>
                                                                    <IconButton onClick={(event: any) => onOpenLink(event, item?.publication?._id)}>
                                                                        <OpenInNewIcon className={styles.icon} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                        :
                                        <TableContainer className={styles.table}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow className={styles.head}>
                                                        <TableCell className={styles.cell}>Foto</TableCell>
                                                        <TableCell className={styles.cell}>Vehiculo</TableCell>
                                                        <TableCell className={styles.cell}>Precio</TableCell>
                                                        <TableCell className={styles.cell}>Kilometraje</TableCell>
                                                        <TableCell className={styles.cell}>Estado</TableCell>
                                                        <TableCell className={styles.cell}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        myBuys?.map((item: any, index: number) => {
                                                            let stateCode = "";
                                                            switch(item?.publication?.status_brief?.code){
                                                                case "payment":
                                                                case "delivered":
                                                                case "sold_out":
                                                                    stateCode = styles.green
                                                                    break;
                                                                case "inspection":
                                                                case "documentation_signature":
                                                                    stateCode = styles.gray
                                                                    break;
                                                                default:
                                                                    stateCode = styles.blue
                                                                    break;
                                                            }
                                                            return <TableRow className={styles.row} onClick={() => handlePressRow(item?._id)} key={index}>
                                                                <TableCell className={styles.cell}>
                                                                    <div className={styles.picture}>
                                                                        <img src={(item?.publication?.pictures && item?.publication?.pictures[0]?.path) ?? images.noImagePublication} alt="Buy" />
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className={`${styles.cell} ${styles.bold} ${styles.ellipsis}`}>
                                                                    <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.publication?.primary_description} ${item?.publication?.title}`}</p>
                                                                </TableCell>
                                                                <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                                    <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.publication?.price)}</p>
                                                                </TableCell>
                                                                <TableCell className={styles.cell}>
                                                                    <p className={styles.text}>{addPointsInText(item?.publication?.secondary_description)} km</p>
                                                                </TableCell>
                                                                <TableCell className={styles.cell}>
                                                                    <div className={`${styles.text} ${styles.state} ${stateCode}`}>{item?.publication?.status_brief?.name.toUpperCase()}</div>
                                                                </TableCell>
                                                                <TableCell className={styles.cell}>
                                                                    <div className={styles.action}>
                                                                        <IconButton onClick={(event: any) => onOpenLink(event, item?.publication?._id)}>
                                                                            <OpenInNewIcon className={styles.icon} />
                                                                        </IconButton>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                    </div>
                                    <Pagination
                                        page={page}
                                        setPage={setPage}
                                        className={styles.pagination}
                                        total={totalMyBuys}
                                    />
                                </div>
                            </ProfileCard>
                }
            </>
    )
};
export default EntryPoint;