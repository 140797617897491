export const images = {
    logotipo: require("../assets/images/logo/logotipo.svg").default,
    logotipoBeta: require("../assets/images/logo/beta/logotipo.svg").default,
    isotipo: require("../assets/images/logo/isotipo.svg").default,
    isoLogoTipo: require("../assets/images/logo/isologotipo.svg").default,
    isoLogoTipoAnimated: require("../assets/images/logo/isologotipoAnimated.svg").default,
    isoLogoTipoBeta: require("../assets/images/logo/beta/isologotipo.svg").default,
    logoApp: require("../assets/images/logo/logoApp.svg").default,
    launcherApp: require("../assets/images/logo/ic_launcher.png"),
    profileDocuments: require("../assets/images/profileDocuments.png"),
    profileDocumentsSelected: require("../assets/images/profileDocumentsSelected.png"),
    profileMyAccount: require("../assets/images/profileMyAccount.png"),
    profileMyAccountSelected: require("../assets/images/profileMyAccountSelected.png"),
    profileMyBuys: require("../assets/images/profileMyBuys.png"),
    profileMyBuysSelected: require("../assets/images/profileMyBuysSelected.png"),
    profileFavorites: require("../assets/images/profileFavorites.png"),
    profileFavoritesSelected: require("../assets/images/profileFavoritesSelected.png"),
    profileMyPublications: require("../assets/images/profileMyPublications.png"),
    profileMyPublicationsSelected: require("../assets/images/profileMyPublicationsSelected.png"),
    profileLogOut: require("../assets/images/profileLogOut.png"),
    homeBkgr1: require("../assets/images/bkgrHome1.webp"),
    homeBkgr2: require("../assets/images/bkgrHome2.webp"),
    homeBkgr3: require("../assets/images/bkgrHome3.webp"),
    search: require("../assets/icons/search.png"),
    deportivo: require("../assets/images/deportivo.png"),
    suv: require("../assets/images/suv.png"),
    van: require("../assets/images/van.png"),
    sedan: require("../assets/images/sedan.png"),
    hatchback: require("../assets/images/hatchback.png"),
    pickup: require("../assets/images/pickup.png"),
    searchCar: require("../assets/icons/searchCar.png"),
    publication: require("../assets/icons/publication.png"),
    calendar: require("../assets/icons/calendar.png"),
    documents: require("../assets/icons/documents.png"),
    crashes: require("../assets/icons/crashes.png"),
    multiple: require("../assets/icons/multiple.png"),
    paperLess: require("../assets/icons/paperLess.png"),
    webCheck: require("../assets/icons/webCheck.png"),
    secure: require("../assets/icons/secure.png"),
    payCar: require("../assets/icons/payCar.png"),
    money: require("../assets/icons/money.png"),
    keys: require("../assets/icons/keys.png"),
    googlePlay: require("../assets/images/googlePlay.png"),
    appleStore: require("../assets/images/appleStore.png"),
    logo: require("../assets/icons/logo.png"),
    facebook: require("../assets/icons/facebook.png"),
    instagram: require("../assets/icons/instagram.png"),
    linkedin: require("../assets/icons/linkedin.png"),
    carPub: require("../assets/images/carPub.jpg"),
    dots: require("../assets/images/dots.png"),
    eye: require("../assets/icons/eye.png"),
    eyeOff: require("../assets/icons/eyeOff.png"),
    loginBackground: require("../assets/images/loginBackground.webp"),
    registerBackground: require("../assets/images/registerBackground.webp"),
    noBuys: require("../assets/images/no_buys.png"),
    noFiles: require("../assets/images/noFiles.png"),
    noPublications: require("../assets/images/no_publications.png"),
    bigCarPub: require("../assets/images/bigCarPub.jpg"),
    showDetail: require("../assets/icons/show_details.png"),
    lightShowDetail: require("../assets/icons/light_show_details.png"),
    moreUpload: require("../assets/icons/moreUpload.png"),
    qrCode: require("../assets/icons/QR1.png"),
    deleteBlack: require("../assets/icons/deleteBlack.png"),
    successModal: require("../assets/icons/successModal.png"),
    arrow: require("../assets/icons/arrow.png"),
    loader: require("../assets/images/loader.png"),
    message: require("../assets/icons/message.png"),
    messageReaded: require("../assets/icons/messageReaded.png"),
    inspection: require("../assets/icons/inspection.png"),
    inspectionReaded: require("../assets/icons/inspectionReaded.png"),
    inspectionDenied: require("../assets/icons/inspectionDenied.png"),
    inspectionDeniedReaded: require("../assets/icons/inspectionDeniedReaded.png"),
    profile: require("../assets/images/profile.png"),
    camera: require("../assets/icons/camera.png"),
    noFavorites: require("../assets/images/noFavorites.png"),
    favorite: require("../assets/icons/favorite.png"),
    noFavorite: require("../assets/icons/noFavorite.svg").default,
    backgroundSell: require("../assets/images/background_sell.webp"),
    arrowBackModal: require("../assets/icons/arrow_back_modal.png"),
    chilePatente: require("../assets/images/chilePatente.png"),
    noBuysPublications: require("../assets/images/noBuysPublications.png"),
    noNotifications: require("../assets/images/noNotifications.png"),
    filter: require("../assets/icons/filter.png"),
    close: require("../assets/icons/close.png"),
    closeWhite: require("../assets/icons/closeWhite.png"),
    check: require("../assets/icons/check.png"),
    share: require("../assets/icons/share.svg").default,
    carType: require("../assets/icons/carType.png"),
    fuel: require("../assets/icons/fuel.png"),
    transmission: require("../assets/icons/transmission.png"),
    color: require("../assets/icons/color.png"),
    audioHifi: require("../assets/icons/audioHifi.png"),
    parkingSensor: require("../assets/icons/parkingSensor.png"),
    cruiceSpeed: require("../assets/icons/cruiceSpeed.png"),
    airFilter: require("../assets/icons/airFilter.png"),
    bluetoothWifi: require("../assets/icons/bluetoothWifi.png"),
    electricCrystals: require("../assets/icons/electricCrystals.png"),
    leatherSeats: require("../assets/icons/leatherSeats.png"),
    onBoardComputer: require("../assets/icons/onBoardComputer.png"),
    barCode: require("../assets/images/barCode.png"),
    linzeIsotipo: require("../assets/icons/linzeIsotipo.png"),
    audio_hifi: require("../assets/images/Audio-HiFi-48x48-Gris.png"),
    audio_hifi_selected: require("../assets/images/AudioHiFi-48x48-Blanco.png"),
    btWiFi: require("../assets/images/btWiFi-48x48-Gris.png"),
    btWiFi_selected: require("../assets/images/btWiFi-48x48-Blanco.png"),
    cristales_electricos: require("../assets/images/Cristales-Electricos-48x48-Gris.png"),
    cristales_electricos_selected: require("../assets/images/Cristales-Electricos-48x48-Blanco.png"),
    bank: require("../assets/icons/bank.png"),
    references: require("../assets/icons/references.png"),
    account: require("../assets/icons/account.png"),
    certificate: require("../assets/icons/certificate.png"),
    credit: require("../assets/icons/credit.png"),
    documentation: require("../assets/icons/documentation.png"),
    liquidation: require("../assets/icons/liquidation.png"),
    home: require("../assets/icons/home.png"),
    carLinzeBlue: require("../assets/icons/carLinzeBlue.png"),
    metaImage: require("../assets/images/metaImage.jpg"),
    noBranches: require("../assets/images/noBranches.png"),
    vistas: require("../assets/icons/vistas.png"),
    hearthBlue: require("../assets/icons/hearthBlue.png"),
    clickIcon: require("../assets/icons/clickIcon.png"),
    eyeBlue: require("../assets/icons/eyeBlue.png"),
    carLinzeBlueLight: require("../assets/icons/carLinzeBlueLight.png"),
    backgroundHistory: require("../assets/images/background_history.png"),
    noSellers: require("../assets/images/noSellers.png"),
    notFoundPage: require("../assets/images/notFoundPage.png"),
    linze_360_icon: require("../assets/icons/linze_360_icon.png"),
    seller: require("../assets/icons/seller.png"),
    sellerSelected: require("../assets/icons/seller_selected.png"),
    sellerBackground: require("../assets/icons/seller_background.png"),
    dealership: require("../assets/icons/dealership.png"),
    dealershipSelected: require("../assets/icons/dealership_selected.png"),
    dealershipBackground: require("../assets/icons/dealership_background.png"),
    branch: require("../assets/icons/branch.png"),
    branchSelected: require("../assets/icons/branch_selected.png"),
    branchBackground: require("../assets/icons/branch_background.png"),
    notificationDefaultReaded: require("../assets/icons/notificationDefaultReaded.png"),
    notificationDefaultNoReaded: require("../assets/icons/notificationDefaultNoReaded.png"),
    noImagePublication: require("../assets/images/noImagePublication.jpg"),
    google: require("../assets/icons/google.svg").default,
    attention: require("../assets/icons/attention.png"),
    firstPictureUpload: require("../assets/images/first_picture_upload.png"),
    secondPictureUpload: require("../assets/images/second_picture_upload.png"),
    thirdPictureUpload: require("../assets/images/third_picture_upload.png"),
    fourPictureUpload: require("../assets/images/four_picture_upload.png"),
    successPictureUpload: require("../assets/images/success_picture_upload.png"),
    check_blue: require("../assets/icons/check_blue.svg").default,
    financingBonus: require("../assets/icons/financingBonus.svg").default,
    valeVista: require("../assets/images/vale-vista.png"),
    pagaEnLinea: require("../assets/images/paga-en-linea.png"),
    purpleEye: require("../assets/icons/eye-purple.png"),
    purpleCursor: require("../assets/icons/cursor-purple.png"),
    purpleVistas: require("../assets/icons/vistas-purple.png"),
    purpleHeart: require("../assets/icons/heart-purple.png"),
    linzeRoundIsoLogo: require("../assets/icons/linze-round-isoLogo.png"),
    purpleRoundCheckmark: require("../assets/icons/purple-round-checkmark.png"),
    comparaLogo: require("../assets/images/compara-logo.png"),
};


export const caracteristics = {
    color: require("../assets/icons/caracteristics/color.svg").default,
    door: require("../assets/icons/caracteristics/door.svg").default,
    fuel: require("../assets/icons/caracteristics/fuel.svg").default,
    model: require("../assets/icons/caracteristics/model.svg").default,
    transmission: require("../assets/icons/caracteristics/transmission.svg").default,
    type: require("../assets/icons/caracteristics/type.svg").default,
    version: require("../assets/icons/caracteristics/version.svg").default,
    year: require("../assets/icons/caracteristics/year.svg").default,
    motor: require("../assets/icons/caracteristics/motor.svg").default,
    mileage: require("../assets/icons/caracteristics/mileage.svg").default
};

export const conforts: any = {
    air_filter: require("../assets/icons/conforts/airFilter.png"),
    audio_hifi: require("../assets/icons/conforts/audio_hifi.svg").default,
    electric_crystals: require("../assets/icons/conforts/electricCristals.png"),
    leather_seats: require("../assets/icons/conforts/leatherSeats.svg").default,
    on_board_computer: require("../assets/icons/conforts/onboardComputer.svg").default,
    parking_sensor: require("../assets/icons/conforts/parkingSensor.svg").default,
    bt_wifi: require("../assets/icons/conforts/wifi.svg").default,
    cruice_speed: require("../assets/icons/conforts/cruiseSpeed.svg").default
};