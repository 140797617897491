import { useEffect, useMemo, useState } from "react";
import styles from "./counterOfferSidebar.module.scss";
import { CounterOfferSidebarProps } from "../../interfaces/components";
import SidebarShared from '../../components/Sidebar/Sidebar';
import ButtonShared from "../Button/Button";
import { useToast } from "../../helpers/hooks";
import TimeLine from "../../pages/Profile/MyPublications/Management/TimeLine";
import TextFieldShared from "../TextField/TextField";
import ModalAcceptRejectOffer from "../ModalAcceptRejectOffer";
import { addPointsInText } from "../../helpers/app";
import { useNavigate } from "react-router-dom";

const CounterOfferSidebar = ({ 
    open,
    onClose,
    offerId,
    getPublication,
    publication,
    cleanPublication,
    getOffersPublication,
    publicationId,
    counterOffers,
    getCounterOffers, 
    cleanGetCounterOffers, 
    postCounterOffers, 
    cleanPostCounterOffers, 
    putCounterOffers, 
    cleanPutCounterOffers,
    putOffersPublication,
    cleanOfferPut
 }: CounterOfferSidebarProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const [section, setSection] = useState<string>("history");
    const [price, setPrice] = useState<string>("0");
    const [lastSection, setLastSection] = useState<any>({type: "offer", status: "pending"});
    const [acceptRejectOffer, setAcceptRejectOffer] = useState<any>({open: false, type: "accepted", price: "0"});
    
    const offersDataNormalized = useMemo(() => {
        return counterOffers?.data && counterOffers?.data.map((item: any) => (
            {
                title: item.title ?? "",
                price: `$ ${addPointsInText(item.price) ?? 0}`,
                description: item?.description ?? ""
            }
        ))
    }, [counterOffers?.data]);

    const onCloseAcceptRejectOffer = () => {
        onClose();
        setAcceptRejectOffer({...acceptRejectOffer, open: false})
    }

    const onCounterOffer = () => {
        if(price !== "0"){
            postCounterOffers(offerId, parseInt(price));
        }
    }

    const onReject = () => {
        setAcceptRejectOffer({...acceptRejectOffer, price: offersDataNormalized[offersDataNormalized.length - 1].price, type: "rejected"});
        if(lastSection?.type === "offer"){
            putOffersPublication(offerId, "rejected");
        }else{
            const counterOffer = counterOffers?.data.find((item: any) => item?.type === "counteroffer");
            putCounterOffers(counterOffer?._id, "rejected");
        }
    }

    const onAccept = () => {
        setAcceptRejectOffer({...acceptRejectOffer, price: offersDataNormalized[offersDataNormalized.length - 1].price, type: "accepted"});
        if(lastSection?.type === "offer"){
            putOffersPublication(offerId, "accepted");
        }else{
            const counterOffer = counterOffers?.data.find((item: any) => item?.type === "counteroffer");
            putCounterOffers(counterOffer?._id, "accepted");
        }
    }

    useEffect(() => {
        if(counterOffers?.fetching === "failure" && open){
            toast("Ha ocurrido un error al mostrar la oferta en detalle");
            cleanGetCounterOffers();
            onClose();
        }
        if(counterOffers?.fetching === "success" && open){
            setLastSection({
                ...lastSection,
                type: (counterOffers?.data && counterOffers?.data[counterOffers?.data.length - 1].type) ?? "offer",
                status: (counterOffers?.data && counterOffers?.data[counterOffers?.data.length - 1].status)
            });
            cleanGetCounterOffers();
        }
    }, [counterOffers?.fetching]);

    useEffect(() => {
        if(counterOffers?.post?.fetching === "failure" && open){
            toast("Ha ocurrido un error al enviar la contraoferta");
            cleanPostCounterOffers();
            return;
        }
        if(counterOffers?.post?.fetching === "success" && open){
            setSection("history");
            toast("Contraoferta enviada exitosamente");
            getCounterOffers(offerId);
            cleanPostCounterOffers();
        }
    }, [counterOffers?.post?.fetching]);

    // Aceptar o rechazar contraoferta del vendedor
    useEffect(() => {
        if(counterOffers?.put?.fetching === "failure" && open){
            toast(`Ha ocurrido un error al ${acceptRejectOffer.type === "accepted" ? "aceptar" : "rechazar"} la contraoferta`);
            cleanPutCounterOffers();
            return;
        }
        if(counterOffers?.put?.fetching === "success" && open){
            setAcceptRejectOffer({...acceptRejectOffer, open: true});
            getCounterOffers(offerId);
            cleanPutCounterOffers();
            if(acceptRejectOffer?.type === "accepted"){
                navigate(`/publication/${publicationId}`);
            }
        }
    }, [counterOffers?.put?.fetching]);

    useEffect(() => {
        if(publication?.fetching === "failure" && open){
            toast(`Ha ocurrido un error al mostrar la publicación`);
            cleanPublication();
        }
        if(publication?.fetching === "success" && open){
            cleanPublication();
        }
    }, [publication?.fetching]);

    useEffect(() => {
        if(open){
            getPublication(publicationId);
            getCounterOffers(offerId);
            setSection("history");
        }
    }, [open]);

    return (
        <>
            <ModalAcceptRejectOffer
                variant="buy"
                type={acceptRejectOffer?.type}
                open={acceptRejectOffer?.open}
                onClose={onCloseAcceptRejectOffer}
                price={acceptRejectOffer?.price}
            />
            <SidebarShared 
                variant="close" 
                open={open} 
                onBack={onClose}
                id="counterOfferSidebarContent"
                className={styles.sidebar}
            >
                <div className={styles.sidebarContent}>
                    <p className={styles.title}>{section === "history" ? "Ver oferta" : "Contraoferta"}</p>
                    {/* <p className={styles.description}>{section === "history" ? "Has recibido la siguiente oferta por tu:" : "Envía tu oferta de precio para su aprobación por parte del vendedor."}</p> */}
                    {
                        section === "history" &&
                        <>
                            <div className={styles.vehicle}>
                                <p>{publication?.data?.title}</p>
                            </div>
                            <TimeLine
                                data={offersDataNormalized} 
                                loading={false} 
                                image={false}
                                className={styles.timeline}
                            />
                            <div className={styles.grid}>
                                {
                                    (lastSection?.type !== "offer" && lastSection?.type !== "final-counteroffer") && (lastSection?.type === "counteroffer" && lastSection?.status === "pending") &&
                                    <>
                                        <ButtonShared
                                            onPress={() => setSection("counterOffer")}
                                            title="Realizar contraoferta"
                                            className={`${styles.counterOffer} ${styles.button}`}
                                            disabled={counterOffers?.put?.fetching === "loading"}
                                        />
                                        <ButtonShared
                                            onPress={onReject}
                                            title="Rechazar"
                                            tertiary
                                            size="small"
                                            className={`${styles.reject} ${styles.button}`}
                                            loading={counterOffers?.put?.fetching === "loading" && acceptRejectOffer?.type === "rejected"}
                                            disabled={counterOffers?.put?.fetching === "loading" && acceptRejectOffer?.type === "accepted"}
                                        />
                                        <ButtonShared
                                            onPress={onAccept}
                                            title="Aceptar"
                                            primary
                                            size="small"
                                            className={`${styles.accept} ${styles.button}`}
                                            loading={counterOffers?.put?.fetching === "loading" && acceptRejectOffer?.type === "accepted"}
                                            disabled={counterOffers?.put?.fetching === "loading" && acceptRejectOffer?.type === "rejected"}
                                        />
                                    </>
                                }
                            </div>
                        </>
                    }
                    {
                        section === "counterOffer" &&
                        <>
                            <TextFieldShared
                                variant="borderless"
                                name="price"
                                value={price}
                                onChange={(value: any) => setPrice(value)}
                                className={styles.input}
                                onEnter={onCounterOffer}
                                autoFocus={true}
                                size="48"
                            />
                            <p className={`${styles.price} ${styles.text}`}>
                                <span>Precio de la publicación</span>
                                <span><strong>$ {addPointsInText(publication?.data?.price)}</strong></span>
                            </p>
                            <p className={`${styles.offer} ${styles.text}`}>
                                <span>Precio de la contraoferta</span>
                                <span><strong>{addPointsInText(offersDataNormalized[offersDataNormalized.length - 1].price)}</strong></span>
                            </p>
                            <p className={`${styles.important} ${styles.text}`}>
                                <span><strong>Importante:</strong></span>
                                <span>Solo puedes enviar una contraoferta</span>
                            </p>
                            <div className={styles.buttons}>
                                <ButtonShared
                                    onPress={() => setSection("history")}
                                    title="Atras"
                                    tertiary
                                    className={`${styles.back} ${styles.button}`}
                                    disabled={counterOffers?.post?.fetching === "loading"}
                                />
                                <ButtonShared
                                    onPress={onCounterOffer}
                                    title="Enviar"
                                    primary
                                    className={`${styles.send} ${styles.button}`}
                                    loading={counterOffers?.post?.fetching === "loading"}
                                    disabled={price === "0" || price === ""}
                                />
                            </div>
                        </>
                    }
                </div>
            </SidebarShared>
        </>
    )
}

export default CounterOfferSidebar;