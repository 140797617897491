import { createSlice } from "@reduxjs/toolkit";

const initialState: initialStateRequestProps = {
    data: []
}

const requestsSlice = createSlice({
    name: 'requests',
    initialState,
    reducers: {
        accumulateRequest: (state, action) => {
            state.data = state.data.concat(action.payload);
        },
        cleanRequest: (state) => {
            state.data = initialState.data;
        },
        setAccumulateRequest: (state, action) => {
            state.data = action.payload;
        }
    }
});

export const {
    accumulateRequest,
    cleanRequest,
    setAccumulateRequest
} = requestsSlice.actions;

export default requestsSlice.reducer;