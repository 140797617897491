import { NavOptionsProps } from "../interfaces/navigation";

export const navOptions: NavOptionsProps[] = [
    {
        title: "Ingresar",
        to: "/login",
        id: "login"
    },
    {
        title: "Registrarse",
        to: "/register",
        id: "register"
    },
    {
        title: "Comprar",
        to: "/search",
        id: "search"
    },
    {
        title: "Vende tu auto",
        to: "/sell",
        id: "sell"
    },
    {
        title: "Notificaciones",
        to: "/notifications",
        id: "notifications"
    },
    {
        title: "Perfil",
        to: "/profile",
        id: "profile"
    }
];