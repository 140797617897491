import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import NoDataList from "../../../../components/NoDataList";
import ProfileCard from "../../../../components/ProfileCard";
import { images } from "../../../../constants/images";
import { MyPublicationsProps } from "../../../../interfaces/components";
import styles from "./entryPoint.module.scss";
import { useProfileRole, useToast } from "../../../../helpers/hooks";
import { addPointsInText } from "../../../../helpers/app";
import SettingsIcon from '@mui/icons-material/Settings';
import PauseModal from "../../../../containers/Profile/MyPublications/Management/Pause/PauseContainer";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Pagination from "../../../../components/Pagination";

const EntryPoint = ({ 
    user,
    getMyPublicationsRequest, 
    myPublications,
    pause
}: MyPublicationsProps) => {
    const toast = useToast();
    const navigate = useNavigate();
    const profileRole = useProfileRole();
    const params = useParams();
    const location = useLocation();
    const [page, setPage] = useState<number>(1);
    const [profile, setProfile] = useState<any>("");
    const [loadingPubs, setLoadingPubs] = useState<boolean>(false);
    const [publicationsAux, setPublicationsAux] = useState<any>([]);
    const [onPausePublication, setOnPausePublication] = useState<any>("");
    const [totalPublications, setTotalPublications] = useState<number>(1);

    const matches = useMediaQuery('(max-width:1024px)');
	const [isSmallScreen, setIsSmallScreen] = useState(matches);
  
	useEffect(() => {
	  setIsSmallScreen(matches);
	}, [matches]);

    const [modalPause, setModalPause] = useState<any>({open: false, publication: ""});

    const handlePressRow = (item: any) => {
        navigate(`/profile/my_publications/${item?._id}/management`);
    }
    
    const onSettings = (item: any, event: any) => {
        event.stopPropagation();
        navigate(`/profile/my_publications/${item?._id}/detail`);
    }

    const onPause = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
        event.stopPropagation();
        setModalPause({open: true, publication: item});
    }

    useEffect(() => {
        if (pause?.fetching === "success") {
            getMyPublicationsRequest(page);
        }
    }, [pause?.fetching]);

    useEffect(() => {
        if(myPublications?.fetching === "loading" && publicationsAux?.length > 0){
            setLoadingPubs(true);
        }
        if(myPublications?.fetching === "failure"){
            toast("Ha ocurrido un error al mostrar las publicaciones");
        }
        if(myPublications?.fetching === "success"){
            setPublicationsAux(myPublications?.data);
            setTotalPublications(Math.ceil(myPublications?.total / 20));
            setLoadingPubs(false);
        }
    }, [myPublications?.fetching]);

    useEffect(() => {
        getMyPublicationsRequest(page);
    }, [location, page]);
    
    useEffect(() => {
        setProfile(profileRole(user?.profile?.code));
    }, []);
   
    return (
        params.id 
        ? 
            <Outlet />
        :
            <>
                <PauseModal
                    open={modalPause?.open} 
                    setOpen={() => setModalPause({...modalPause, open: false})}
                    loading={pause?.fetching === "loading"}
                    publication={modalPause?.publication}
                />
                {
                    (myPublications?.fetching === "loading" && publicationsAux?.length === 0) 
                    ?
                        <div className={styles.loaderPublications} id="loaderPublications">
                            <CircularProgress 
                                size={styles.loader}
                                classes={{
                                    colorPrimary: styles.loader
                                }}
                            />
                            <p className={styles.text}>Cargando publicaciones</p>
                        </div>
                    :
                        <ProfileCard>
                            <div className={styles.myPublicationsContainer}>
                                <div id="publicationsList" className={styles.list}>
                                    {
                                        publicationsAux && publicationsAux.length === 0
                                        ?
                                            <div className={styles.noPublications}>
                                                <NoDataList
                                                    title="No encontramos resultados para tu búsqueda"
                                                    text="Inténtalo de nuevo o prueba utilizando otros filtros"
                                                    image={images.noPublications}
                                                />
                                            </div>
                                        :
                                        isSmallScreen ?
                                            publicationsAux?.map((item: any, index: number) => {
                                                let stateCode = "";
                                                switch(item?.status_brief?.code){
                                                    case "rejected":
                                                        stateCode = styles.red
                                                        break;
                                                    case "payment":
                                                    case "delivered":
                                                    case "sold_out":
                                                        stateCode = styles.green
                                                        break;
                                                    case "inspection":
                                                    case "pending":
                                                    case "documentation_signature":
                                                        stateCode = styles.gray
                                                        break;
                                                    default:
                                                        stateCode = styles.blue
                                                        break;
                                                }
                                                return(
                                                    <div className={styles.cardContainer} onClick={() => handlePressRow(item)} key={index}>
                                                        <img src={item?.pictures[0]?.path ?? images.noImagePublication} className={item?.paused && styles.paused} alt="linzecar linze publication" />
                                                        <div className={styles.cardDescription}>
                                                            <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.primary_description} ${item?.title}`}</p>
                                                            <p className={styles.text}>{item?.vehicle_data?.plate}</p>
                                                            <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.price)}</p>
                                                            <div className={styles.cardOptionsContainer}>
                                                                <div className={`${styles.text} ${styles.state} ${item?.paused ? styles.gray : stateCode}`}>{item?.paused ? "PAUSADO" : item?.status_brief?.name.toUpperCase()}</div>
                                                                <div className={styles.cardAction}>
                                                                    {
                                                                        (item?.status_brief?.code !== "pending" && item?.status_brief?.code !== "rejected") &&
                                                                        <IconButton title={item?.paused ? "Despausar" : "Pausar"} onClick={(event: any) => onPause(event, item)}>
                                                                            {
                                                                                pause?.fetching === "loading" && onPausePublication === item?._id
                                                                                ?
                                                                                    <CircularProgress 
                                                                                        size={styles.loader}
                                                                                        classes={{
                                                                                            colorPrimary: styles.loader
                                                                                        }}
                                                                                    />
                                                                                :
                                                                                    item?.paused 
                                                                                    ?
                                                                                        <PlayArrowIcon className={styles.icon} />
                                                                                    :
                                                                                        <PauseIcon className={styles.icon} />
                                                                                        
                                                                            }
                                                                        </IconButton>
                                                                    }
                                                                    <IconButton onClick={(event: any) => onSettings(item, event)}>
                                                                        <SettingsIcon className={styles.icon} />
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                        :<TableContainer className={`${styles.table} ${loadingPubs && styles.loading}`}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow className={styles.head}>
                                                            <TableCell className={styles.cell}>Foto</TableCell>
                                                            <TableCell className={styles.cell}>Vehiculo</TableCell>
                                                            <TableCell className={styles.cell}>Patente</TableCell>
                                                            <TableCell className={styles.cell}>Precio</TableCell>
                                                            <TableCell className={styles.cell}>Estado</TableCell>
                                                            <TableCell className={styles.cell}></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            publicationsAux?.map((item: any, index: number) => {
                                                                let stateCode = "";
                                                                switch(item?.status_brief?.code){
                                                                    case "rejected":
                                                                        stateCode = styles.red
                                                                        break;
                                                                    case "payment":
                                                                    case "delivered":
                                                                    case "sold_out":
                                                                        stateCode = styles.green
                                                                        break;
                                                                    case "inspection":
                                                                    case "pending":
                                                                    case "documentation_signature":
                                                                        stateCode = styles.gray
                                                                        break;
                                                                    default:
                                                                        stateCode = styles.blue
                                                                        break;
                                                                }
                                                                return <TableRow className={styles.row} onClick={() => handlePressRow(item)} key={index}>
                                                                    <TableCell className={styles.cell}>
                                                                        <div className={styles.picture}>
                                                                            <img src={item?.pictures[0]?.path ?? images.noImagePublication} className={item?.paused && styles.paused} alt="linzecar linze publication" />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={`${styles.cell} ${styles.bold} ${styles.ellipsis}`}>
                                                                        <p className={`${styles.text} ${styles.vehicle}`}>{`${item?.primary_description} ${item?.title}`}</p>
                                                                    </TableCell>
                                                                    <TableCell className={styles.cell}>
                                                                        <p className={styles.text}>{item?.vehicle_data?.plate}</p>
                                                                    </TableCell>
                                                                    <TableCell className={`${styles.cell} ${styles.bold}`}>
                                                                        <p className={`${styles.text} ${styles.price}`}>$ {addPointsInText(item?.price)}</p>
                                                                    </TableCell>
                                                                    <TableCell className={styles.cell}>
                                                                        <div className={`${styles.text} ${styles.state} ${item?.paused ? styles.gray : stateCode}`}>{item?.paused ? "PAUSADO" : item?.status_brief?.name.toUpperCase()}</div>
                                                                    </TableCell>
                                                                    <TableCell className={styles.cell}>
                                                                        <div className={styles.action}>
                                                                            {
                                                                                (item?.status_brief?.code !== "pending" && item?.status_brief?.code !== "rejected") &&
                                                                                <IconButton title={item?.paused ? "Despausar" : "Pausar"} onClick={(event: any) => onPause(event, item)}>
                                                                                    {
                                                                                        pause?.fetching === "loading" && onPausePublication === item?._id
                                                                                        ?
                                                                                            <CircularProgress 
                                                                                                size={styles.loader}
                                                                                                classes={{
                                                                                                    colorPrimary: styles.loader
                                                                                                }}
                                                                                            />
                                                                                        :
                                                                                            item?.paused 
                                                                                            ?
                                                                                                <PlayArrowIcon className={styles.icon} />
                                                                                            :
                                                                                                <PauseIcon className={styles.icon} />
                                                                                                
                                                                                    }
                                                                                </IconButton>
                                                                            }
                                                                            <IconButton onClick={(event: any) => onSettings(item, event)}>
                                                                                <SettingsIcon className={styles.icon} />
                                                                            </IconButton>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                    }
                                </div>
                                {
                                    (publicationsAux && publicationsAux.length > 0) &&
                                    <Pagination
                                        page={page}
                                        setPage={setPage}
                                        className={styles.pagination}
                                        total={totalPublications}
                                    />
                                }
                            </div>
                        </ProfileCard>
                }
            </>
    )
};
export default EntryPoint;