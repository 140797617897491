import styles from "./footer.module.scss";
import { Link, useLocation } from "react-router-dom";
import { FooterProps } from "../../interfaces/components";
import { useWindowSize } from "use-hooks";

const Footer = ({
    user,
    searchType,
    setTypeReducer
}: FooterProps) => {
    const location = useLocation();
    const windowSize = useWindowSize();

    const handlePressFilter = (value: string) => {
        if(value === ""){
            setTypeReducer([]);
            return;
        }
        if(value === 'van' || value === 'deportivo') setTypeReducer([]);
        else setTypeReducer([value]);
    }

    return (
        <footer className={styles.footerContainer}>
            <section className={styles.footerNav}>
                <div className={styles.item}>
                <h3 className={styles.title}>Preguntas Frecuentes</h3>
                    <ul>
                        {
                            windowSize.width < 1024 
                            ?
                                <li>
                                    <Link to="/frequent_questions/buy" onClick={() => handlePressFilter("")} className={`${styles.link} ${styles.linkResponsive}`}>Preguntas Frecuentes</Link>
                                </li>
                            :
                            <>
                                <li>
                                    <Link to="/frequent_questions/buy" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/frequent_questions/buy") && styles.active}`}>¿Cómo comprar?</Link>
                                </li>
                                <li>
                                    <Link to="/frequent_questions/sell" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/frequent_questions/sell") && styles.active}`}>¿Cómo vender?</Link>
                                </li>
                                <li>
                                    <Link to="/frequent_questions/financing" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/frequent_questions/financing") && styles.active}`}>Financiamiento</Link>
                                </li>
                                <li>
                                    <Link to="/frequent_questions/pictures" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/frequent_questions/pictures") && styles.active}`}>Guía para publicar</Link>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                <div className={styles.item}>
                <h3 className={styles.title}>Vender</h3>
                    <ul>
                        {
                            windowSize.width < 1024 
                            ?
                                <li>
                                    <Link to="/sell/search" onClick={() => handlePressFilter("")} className={`${styles.link} ${styles.linkResponsive}`}>Vender</Link>
                                </li>
                            :
                            <li>
                                <Link to="/sell/search" onClick={() => handlePressFilter("")} className={`${styles.link} ${!location.pathname.match("frequent_questions/sell") && location.pathname.match("/sell") && styles.active}`}>Publica tu auto</Link>
                            </li>
                        }
                    </ul>
                </div>
                <div className={styles.item}>
                <h3 className={styles.title}>{!user ? "Comprar" : "Buscar"}</h3>
                    <ul>
                        {
                            windowSize.width < 1024 
                            ?
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("")} className={`${styles.link} ${styles.linkResponsive}`}>{!user ? "Comprar" : "Buscar"}</Link>
                                </li>
                            :
                            <>
                                <li>
                                    <Link to="/search" className={`${styles.link} ${location.pathname.match("/search") && !location.pathname.match("/sell/search") && searchType.length === 0 && styles.active}`}>Búsqueda</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("suv")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "suv" && styles.active}`}>SUV</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("sedan")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "sedan" && styles.active}`}>Sédan</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("hatchback")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "hatchback" && styles.active}`}>Hatchback</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("deportivo")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "deportivo" && styles.active}`}>Deportivo</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("van")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "van" && styles.active}`}>Van</Link>
                                </li>
                                <li>
                                    <Link to="/search" onClick={() => handlePressFilter("pickup")} className={`${styles.link} ${location.pathname.match("/search") && searchType[0] === "pickup" && styles.active}`}>Pickup</Link>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                <div className={styles.item}>
                    <h3 className={styles.title}>Perfil</h3>
                    <ul>
                        {
                            windowSize.width < 1024 
                            ?
                                <li>
                                    <Link to="/profile/my_account" onClick={() => handlePressFilter("")} className={`${styles.link} ${styles.linkResponsive}`}>Perfil</Link>
                                </li>
                            :
                                !user
                                ?
                                    <>
                                        <li>
                                            <Link to="/profile/my_account" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/my_account") && styles.active}`}>Mi cuenta</Link>
                                        </li>
                                        <li>
                                            <Link to="/profile/my_publications" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/my_publications") && styles.active}`}>Mis publicaciones</Link>
                                        </li>
                                        <li>
                                            <Link to="/profile/my_buys" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/my_buys") && styles.active}`}>Mis compras</Link>
                                        </li>
                                        <li>
                                            <Link to="/profile/favorites" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/favorites") && styles.active}`}>Favoritos</Link>
                                        </li>
                                        <li>
                                            <Link to="/profile/documentation" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/documentation") && styles.active}`}>Documentación</Link>
                                        </li>
                                    </>
                                :
                                    <>
                                        <li>
                                            <Link to="/profile/my_account" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/my_account") && styles.active}`}>Mi cuenta</Link>
                                        </li>
                                        <li>
                                            <Link to="/profile/settings" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/profile/settings") && styles.active}`}>Configuración</Link>
                                        </li>
                                        <li>
                                            <Link to="/policies" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/politics") && styles.active}`}>Politicas de privacidad</Link>
                                        </li>
                                    </>
                        }
                    </ul>
                </div>
                <div className={styles.item}>
                    <h3 className={styles.title}>Información</h3>
                    <ul>
                        {
                            windowSize.width < 1024 
                            ?
                                <li>
                                    <Link to="/terms" onClick={() => handlePressFilter("")} className={`${styles.link} ${styles.linkResponsive}`}>Información</Link>
                                </li>
                            :
                                <li>
                                    <Link to="/terms" onClick={() => handlePressFilter("")} className={`${styles.link} ${location.pathname.match("/terms") && styles.active}`}>Términos y condiciones</Link>
                                </li>
                        }
                    </ul>
                </div>
            </section>
            <section className={styles.footerContent}>
                <div className={styles.information}>
                    <p>Copyright © 2023 linze. Todos los derechos reservados</p>
                </div>
            </section>
        </footer>
    )
}
export default Footer;