import { useMemo, useEffect, useState } from "react";
import styles from "./carDetail.module.scss";
import { useNavigate } from "react-router-dom";
import { ToggleButton } from "@mui/material";
import { TRANSMISSION_TYPES, TYPE_TYPES, DOORS_TYPES } from "../../../constants/salePost";
import { FUEL_TYPES, COLORS } from "../../../constants/Publication";
import { CarDetailProps } from "../../../interfaces/salePost";
import Button from "../../../components/Button";
import SellModal from "../../../components/SellModal/SellModal";
import ToggleButtonGroupCustom from "../../../components/ToggleButtonGroup/ToggleButtonGroup";
import SelectShared from "../../../components/Select/Select";
import { capitalize } from "../../../helpers/app";

const CardDetail = ({
    color,
    fuel,
    version,
    versions,
    getVersions,
    fetchingVersions,
    setVersion,
    setFuel,
    setColor,
    models, 
    year,
    plate,
    isFoundPlate,
    vehicleType,
    setType,
    transmission,
    setTransmission,
    doors,
    setDoors
}: CarDetailProps) => {
    const navigate = useNavigate();
    const [colorSelected, setColorSelected] = useState("blanco");
    const [fuelSelected, setFuelSelected] = useState<string>("bencina");
    const [versionSelected, setVersionSelected] = useState<any>({id: null, name: null});
    const [typeSelected, setTypeSelected] = useState<string>("");
    const [transmissionSelected, setTransmissionSelected] = useState<string>("");
    const [doorsSelected, setDoorsSelected] = useState<string>('5');
    const [disabled, setDisabled] = useState<boolean>(true);

    const handleChangeVersion = (id: string) => {
        const versionFound = versionsNormalized.find((item: any) => item?.id === id);
        setVersionSelected(versionFound);
    }

    const handleChangeType = (value: string) => {
        setTypeSelected(value);
    }

    const handleChangeTransmission = (value: string) => {
        setTransmissionSelected(value);
    }

    const handleChangeDoors = (value: string) => {
        setDoorsSelected(value);
    }

    const onPressBack = () => {
        if(isFoundPlate){
            navigate(`/sell/location`);
            return;
        }
        navigate("/sell/model");
    }

    const onPressNext = () => {
        setVersion({id: versionSelected.id, label: versionSelected.name});
        setType(typeSelected ?? vehicleType);
        setTransmission(transmissionSelected ?? transmission ?? TRANSMISSION_TYPES[0]);
        setColor(colorSelected.toLocaleLowerCase());
        setFuel(fuelSelected.toLocaleLowerCase());
        setDoors(parseInt(doorsSelected));
        navigate("/sell/records");
    };

    const versionsNormalized: any = useMemo(() => {
        return versions && versions.map((version: any) => (
            {
                id: version?.version_id,
                name: version?.name
            }
        ))
    }, [versions]);

    const typesNormalized: any = useMemo(() => {
        return TYPE_TYPES && TYPE_TYPES.map((type: any) => (
            {
                id: type?.id,
                name: type?.value,
                value: type?.value
            }
        ))
    }, [TYPE_TYPES]);

    const transmissionsNormalized: any = useMemo(() => {
        return TRANSMISSION_TYPES && TRANSMISSION_TYPES.map((transmission: any, index: number) => (
            {
                id: index,
                name: transmission,
                value: transmission
            }
        ))
    }, [TRANSMISSION_TYPES]);

    const colorsNormalized: any = useMemo(() => {
        return COLORS && COLORS.map((color: any, index: number) => (
            {
                id: index,
                name: capitalize(color),
                value: color
            }
        ))
    }, [COLORS]);

    const fuelsNormalized: any = useMemo(() => {
        return FUEL_TYPES && FUEL_TYPES.map((fuel: any, index: number) => (
            {
                id: index,
                name: capitalize(fuel),
                value: fuel
            }
        ))
    }, [FUEL_TYPES]);

    const doorsNormalized: any = useMemo(() => {
        return DOORS_TYPES && DOORS_TYPES.map((doors: any, index: number) => (
            {
                id: index,
                name: doors?.id,
                value: doors?.id
            }
        ))
    }, [DOORS_TYPES]);


    useEffect(() => {
        const colorFound = COLORS.find((item: any) => item === color);

        if(colorFound){
            let capitalizedColor = capitalize(colorFound);
            capitalizedColor && setColorSelected(capitalizedColor);
        }
    }, [COLORS]);

    useEffect(() => {
        const doorsFound = DOORS_TYPES.find((item: any) => item?.id === doors.toString());

        if(doorsFound){
            setDoorsSelected(doorsFound?.id);
        }
    }, [DOORS_TYPES]);

    useEffect(() => {
        if(versionSelected?.name && typeSelected && transmissionSelected && colorSelected && fuelSelected){
            setDisabled(false);
        }else{
            setDisabled(true);
        }
    },[fuelSelected, colorSelected, transmissionSelected, typeSelected, versionSelected]);

    useEffect(() => {
        if(!plate) return navigate("/sell");
    }, [plate]);

    useEffect(() => {
        setVersionSelected({id: version?.id, name: version?.name});
        let capitalizedFuel = capitalize(fuel)
        capitalizedFuel && setFuelSelected(capitalizedFuel);
        getVersions({
            model_id: models.selected?.id, 
            year: year
        });
    }, []);

    return (
        <SellModal 
            step={isFoundPlate ? 3 : 5} 
            title="Detalles"
            onClickBack={onPressBack}
            isFoundPlate={isFoundPlate}
        >
            <div className={styles.inputsContainer}>
                <SelectShared
                    label="Versión"
                    name="version"
                    value={versionSelected?.id}
                    onChange={handleChangeVersion}
                    options={versionsNormalized}
                    loading={fetchingVersions === "loading"}
                    className={styles.input}
                />
                <SelectShared
                    label="Carroceria"
                    name="chasis"
                    value={typeSelected}
                    onChange={handleChangeType}
                    options={typesNormalized}
                    className={styles.input}
                    keyValue="name"
                />
                <SelectShared
                    label="Transmisión"
                    name="transmission"
                    value={transmissionSelected}
                    onChange={handleChangeTransmission}
                    options={transmissionsNormalized}
                    className={styles.input}
                    keyValue="name"
                />
                <SelectShared
                    label="Color"
                    name="color"
                    value={colorSelected}
                    onChange={setColorSelected}
                    options={colorsNormalized}
                    className={styles.input}
                    keyValue="name"
                />
                <SelectShared
                    label="Combustible"
                    name="fuel"
                    value={fuelSelected}
                    onChange={setFuelSelected}
                    options={fuelsNormalized}
                    className={styles.input}
                    keyValue="name"
                />
                <SelectShared
                    label="Puertas"
                    name="doors"
                    value={doorsSelected}
                    onChange={handleChangeDoors}
                    options={doorsNormalized}
                    className={styles.input}
                    keyValue="name"
                />
            </div>
            <Button 
                onPress={onPressNext}
                title="Continuar" 
                primary 
                disabled={disabled} 
                className={styles.submitButton}
            />
        </SellModal>
    )
};

export default CardDetail;