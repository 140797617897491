import { AnyAction } from "redux";
import { getNotifications } from "../../actions/notifications";
import { editUserSuccess, refreshTokenSuccess } from "../../reducers/auth";
import { cleanProfileFetching } from "../../actions/profile";
import { initRefreshType, refreshTokenRequest } from "../../actions/auth";
import { REHYDRATE } from "redux-persist";
import { getDocumentsTypes } from "../../actions/documents";
import { RequestProps } from "../../interfaces";

export default function userMiddleware(store: any) {
    return (next: any) => async (action: AnyAction) => {
        const result = next(action);
        if (action.type === REHYDRATE && store.getState().auth?.fetchingRefreshToken !== "loading") {
            refreshTokenRequest("first_initialization")(store.dispatch, store.getState);
        }
        if (action.type === refreshTokenSuccess) {
            if(store.getState().auth?.refreshType === "first_initialization"){
                if(Array.isArray(store.getState()?.requests?.data)){
                    store.getState().requests.data.forEach((request: RequestProps) => {
                        if(request.params){
                            request.function(...Object.values(request.params))(store.dispatch, store.getState);
                        }else{
                            request.function()(store.dispatch, store.getState);
                        }
                    });
                }
                initRefreshType()(store.dispatch);
                //getDocumentsTypes()(store.dispatch, store.getState);
                //getNotifications()(store.dispatch, store.getState);
            }
        }
        if (action.type === editUserSuccess) {
            setTimeout(() => {
                cleanProfileFetching()(store.dispatch);
            },300)
        }
        return result;
    }
};